import revive_payload_client_40MU9UUm7J from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VFIrbhK9hY from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZOK6ilTw7A from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5pEWga6Pas from "/usr/prnews/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.31.0_vite@5.4.12_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import posthog_client_iED83WoF85 from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.31.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import supabase_client_bCDaEKrp39 from "/usr/prnews/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.31.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_ES6GmtlIO5 from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sAjUlQCjVm from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZNUCS1A6Ap from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jCx2tJelzE from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jM9y0PMxph from "/usr/prnews/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.31.0_typescript@5.6.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/prnews/.nuxt/components.plugin.mjs";
import prefetch_client_PiVvGhvrik from "/usr/prnews/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.17.5_eslint@9.10.0_rollup@4.31.0_typescript@5.6.2_vite@5.4.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_cbQB1lYY8W from "/usr/prnews/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.6.0_rollup@4.31.0/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import sentry_client_VdgAyWZHoF from "/usr/prnews/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+instru_ni3kygtgll6vic7juqgpdnokc4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/prnews/.nuxt/sentry-client-config.mjs";
import directives_o7BSZxZwke from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.31.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import switch_locale_path_ssr_MVdq7HidjO from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.31.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_OFCL3XZPy8 from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.31.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_auth_66fnPBoWcQ from "/usr/prnews/plugins/01.auth.ts";
import segment_client_PKspGsfJUR from "/usr/prnews/plugins/segment.client.ts";
import validate_fk94mvjWUp from "/usr/prnews/plugins/validate.ts";
import vueQuery_HbXfDZmQp2 from "/usr/prnews/plugins/vueQuery.ts";
export default [
  revive_payload_client_40MU9UUm7J,
  unhead_VFIrbhK9hY,
  router_ZOK6ilTw7A,
  _0_siteConfig_5pEWga6Pas,
  posthog_client_iED83WoF85,
  supabase_client_bCDaEKrp39,
  payload_client_ES6GmtlIO5,
  navigation_repaint_client_sAjUlQCjVm,
  check_outdated_build_client_ZNUCS1A6Ap,
  chunk_reload_client_jCx2tJelzE,
  plugin_vue3_jM9y0PMxph,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PiVvGhvrik,
  plugin_cbQB1lYY8W,
  sentry_client_VdgAyWZHoF,
  sentry_client_config_o34nk2sJbg,
  directives_o7BSZxZwke,
  switch_locale_path_ssr_MVdq7HidjO,
  i18n_OFCL3XZPy8,
  _01_auth_66fnPBoWcQ,
  segment_client_PKspGsfJUR,
  validate_fk94mvjWUp,
  vueQuery_HbXfDZmQp2
]