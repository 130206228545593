import { AnalyticsBrowser } from '@segment/analytics-next'
import type { EventProperties } from '@segment/analytics-next'

declare module '#app' {
  interface NuxtApp {
    $analytics: AnalyticsBrowser | null;
  }
}

interface IBinding {
  arg?: string
  value?: boolean | EventProperties
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const analytics = import.meta.env.PROD && config.public.segmentKey
    ? AnalyticsBrowser.load({ writeKey: config.public.segmentKey })
    : null

  analytics?.addSourceMiddleware(async ({ payload, next }) => {
    const { type, context } = payload.obj

    if (type === 'track') {
      const group = await analytics.group()
      const groupId = group.id()

      if (context && groupId) {
        context.groupId = groupId
      }
    }

    next(payload)
  })

  nuxtApp.vueApp.directive('analytics', {
    mounted(el: Element & { clickEvent: () => void }, binding: IBinding) {
      const { arg, value } = binding

      el.clickEvent = function () {
        if (!arg || value === false) {
          return
        }

        analytics?.track(arg, value === true ? {} : value)
      }

      el.addEventListener('click', el.clickEvent)
    },
    unmounted(el) {
      el.removeEventListener('click', el.clickEvent)
    },
  })

  return {
    provide: {
      analytics,
    }
  }
})
